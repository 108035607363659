//个人数据

import React from "react";

function PersonData() {
  return (
    <>
      <div className="unique-width">
          <table className="table table-striped-columns table-bordered table-lg text-center">
            <tbody>
              <tr>
                <th scope="row">昵称</th>
                <td>刃牙</td>
                <th scope="row">Nick</th>
                <td>Zane</td>
              </tr>
              <tr>
                <th scope="row">战队</th>
                <td>赛文搏击</td>
                <th scope="row">Team</th>
                <td>Seven</td>
              </tr>
              <tr>
                <th scope="row">身高</th>
                <td>1米76</td>
                <th scope="row">Height</th>
                <td>176cm</td>
              </tr>
              <tr>
                <th scope="row">体重</th>
                <td>58公斤</td>
                <th scope="row">Weight</th>
                <td>58kg</td>
              </tr>
              <tr>
                <th scope="row">年龄</th>
                <td>16</td>
                <th scope="row">Age</th>
                <td>16</td>
              </tr>
              <tr>
                <th scope="row">星座</th>
                <td>狮子座</td>
                <th scope="row">Zodiac</th>
                <td>Leo</td>
              </tr>
            </tbody>
          </table>
      </div>
    </>
  );
}

export default PersonData;
